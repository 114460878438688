import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import TaskContent from "./TaskContent";
import RawTaskContent from "./RawTaskContent";
import * as constants from "../shared/constants";

export function Started(props) {
  const [form, setForm] = useState({
    repositoryLink: props.task.repositoryLink,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
    setForm((data) => ({
      ...data,
      [name]: value,
    }));
  };

  function formatT(n) {
    return n < 10 ? "0" + n : "" + n;
  }

  function getLocalTime(date) {
    let localDate = null;

    if (!date) return "";

    if (date.includes("Z")) {
      localDate = new Date(date);
    } else {
      localDate = new Date(`${date}Z`);
    }

    let dateString = localDate.toLocaleDateString();
    let hours = localDate.getHours();
    let minutes = localDate.getMinutes();

    return `${dateString} ${formatT(hours)}:${formatT(minutes)}`;
  }

  function formIsValid() {
    const { repositoryLink } = form;
    const errors = {};

    if (!repositoryLink) errors.repositoryLink = "Repository link is required.";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid()) return;

    props.eventHandle(form);
  };

  return (
    <Container maxWidth="lg">
      <Typography
        variant="subtitle2"
        color="secondary"
        component="p"
        style={{ textAlign: "right" }}
      >
        <strong>
          Started Task on:{" "}
          <em>{getLocalTime(props.task.taskStartedDateUtc)}</em>
        </strong>
      </Typography>

      <h3>{props.task.taskTitle}</h3>

      {props.task.taskDescription.introduction ? (
        <div>{props.task.taskDescription.introduction}</div>
      ) : (
        <div></div>
      )}

      <h4>Summary</h4>

      <div>{props.task.taskDescription.summary}</div>

      {props.task.taskDescription.description ? (
        <>
          <h4>Description</h4>
          <RawTaskContent
            taskContent={props.task.taskDescription.description}
          />
        </>
      ) : (
        <div></div>
      )}

      <TaskContent
        functional={props.task.taskDescription.functionalRequirements}
        technical={props.task.taskDescription.technicalRequirements}
        attachments={props.task.taskDescription.attachments}
      />

      <div style={{ marginTop: 20 }}>
        If you have any questions please reach out via email.
      </div>

      <div
        style={{ borderTop: "1px solid #ccc", marginTop: 40, marginBottom: 20 }}
      ></div>

      <h4>Task Submission</h4>
      {props.task.repositoryLink && <h5>
        Please include a readme file with your submission with any necessary
        instructions to set up and run the project (e.g. database setup, any NPM
        initialisation, etc). Please make sure all changes are pushed to the repository
        before clicking on the 'Submit Task'  button.
      </h5>}
      {!props.task.repositoryLink && <h5>
        Please include a file with your submission with any necessary
        instructions to set up and run the project (e.g. database setup, any NPM
        initialisation, etc).
      </h5>}

      <div style={{ marginTop: 20 }}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div>{!props.task.repositoryLink && <div><div>
              <TextField
                name="repositoryLink"
                label="Link to File"
                onChange={handleChange}
                required
                error={errors.repositoryLink ? true : false}
                fullWidth
                margin="normal"
                variant="outlined"
                value={props.task.repositoryLink}
              />
              <Typography variant="body2" color="error">
                {errors.repositoryLink}
              </Typography>
            </div>
            <div className="margin-20"></div></div>           
          }</div>
          <Button
            className="margin-right-10"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit Task
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default Started;
