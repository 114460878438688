import React from "react";

export function TaskPreparation(props) {
  if (props.preperations && props.preperations.length) {
    return (
      <>
        <div>
          You will need the below <em style={{ fontWeight: "bold" }}>before</em>{" "}
          starting the task:
        </div>
        <ul style={{ marginTop: 10 }}>
          {props.preperations.map((value, index) => (
            <li key={index}>{value}</li>
          ))}
        </ul>
      </>
    );
  } else {
    return <div></div>;
  }
}

export default TaskPreparation;
