import React from "react";
import Container from "@material-ui/core/Container";
import * as constants from "./shared/constants";

const LandingPage = () => {
  return (
    <Container maxWidth="lg">
      <div>
        <p>
          This page is only accessible via a link issued by Cleverbit Software.
        </p>

        <p>
          If you have a valid link and still see this message. Please contact
          Cleverbit Software at{" "}
          <em>
            <a href={"mailto:" + constants.showEmail("careers")}>
              {constants.showEmail("careers")}
            </a>
          </em>
        </p>
      </div>
    </Container>
  );
};

export default LandingPage;
