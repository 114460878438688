import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TaskPreparation from "./TaskPreparation";
import Typography from "@material-ui/core/Typography";
import RawTaskContent from "./RawTaskContent";
import * as fileDownloader from "../shared/downloadFile";
import { actionCreators as tasksActions } from "../redux/actions/tasksActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "@material-ui/core/TextField";
import { Checkbox } from "@material-ui/core";

export function PendingStart(props) {

    const handleCollaborator = () => {
        props.addCollaborator(document.getElementById("username").value, window.location.pathname)
        }; 

    const handleValidation = async () => {
        await props.validateCollaborator(document.getElementById("username").value, window.location.pathname);
        };   
        
    const handleClick = async () => {
        await props.setReadTerms(document.getElementById("readTerms").checked);        
    }
        
    return (
        <Container maxWidth="lg">
            <h3>
                Hi{" "}
                <span>
                    {props.task.candidateFirstName} {props.task.candidateLastName}
                </span>
            </h3>

            <Typography
                variant="subtitle1"
                color="secondary"
                style={{ marginTop: 30 }}
            >
                <strong>Please read the below carefully.</strong>
            </Typography>

            <h4>
                Only start the task after reading all the requirements. Make sure you
                are well prepared and have allocated enough time to finish off the task.
            </h4>

            <TaskPreparation
                preperations={props.task.taskDescription.preparationRequirements}
            />

            {props.task.taskDescription.preparationInstructions ? (
                <>
                    <RawTaskContent
                        taskContent={props.task.taskDescription.preparationInstructions}
                    />
                </>
            ) : (
                <div></div> 
            )}

            {props.task.taskDescription.preparationFiles && props.task.taskDescription.preparationFiles.length ? (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            border: "1px solid lightgrey"
                        }}
                    >
                        {props.task.taskDescription.preparationFiles.map((item, index) => (
                            <div key={index} style={{ maxWidth: 900, margin: 20 }}>
                                <Typography
                                    variant="subtitle2"
                                    component="p"
                                    style={{ marginBottom: 5 }}
                                    color="primary"
                                >
                                    {item.name}
                                </Typography>

                                {fileDownloader.getFileFormat(item.name) === "jpg" ||
                                    fileDownloader.getFileFormat(item.name) === "png" ? (
                                    <>
                                        <img
                                            style={{ maxWidth: "100%" }}
                                            alt={item.name}
                                            src={`data:image/${fileDownloader.getFileFormat(item.name)};base64,${item.data
                                                }`}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            variant="outlined"
                                            type="button"
                                            color="primary"
                                            onClick={() => fileDownloader.downloadFile(item.data, item.name)}
                                        >
                                            Download
                                        </Button>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div></div>
            )}
            
            
            {props.task.repositoryLink && <div>
                <h4>
    1. Input your GitHub username in the textbox below, and click on "Invite".<br />
    2. Accept the invitation (sent by email).<br />
    3. Click on "Verify Access".<br />
    4. Accept the terms.<br />
    5. Click on "Start Task".
                </h4>

                <TextField
                label="GitHub Username"
                required
                fullWidth
                margin="normal"
                variant="outlined"
                id="username"
                value={props.username}
                />
                <br/>
                <Button onClick={handleCollaborator}
                        variant="outlined"
                        type="submit"
                        color="primary"
                    >
                        Invite
                </Button>
                <Button onClick={handleValidation}
                        variant="outlined"
                        type="submit"
                        color="primary"
                        style={{margin:5}}
                    >
                        Verify Access
                </Button>

                <table cellPadding={0} cellSpacing={0} style={{ marginTop: 20}}>
                    <tr><td><Checkbox required id="readTerms" onClick={handleClick} />
                        </td>
                        <td>I agree to treat the content of this task as confidential
                    and that it is strictly forbidden to share any part of it with any third party, without a prior written consent from Cleverbit Software.
                            </td></tr>
                </table>

                <div style={{ marginTop: 20 }}>
                    <Button disabled={!props.isValid || !props.readTerms} variant="contained" color="primary" onClick={props.eventHandle}>
                        Start Task
                    </Button>
                </div>
                <div style={{ fontStyle: "italic", marginTop: 10 }}></div>
            </div>}
            {!props.task.repositoryLink && <div>
                <table cellPadding={0} cellSpacing={0} style={{ marginTop: 20}}>
                    <tr><td><Checkbox required id="readTerms" onClick={handleClick} />
                        </td>
                        <td>I agree to treat the content of this task as confidential
                            and that it is strictly forbidden to share any part of it with any third party, without a prior written consent from Cleverbit Software.
                            </td></tr>
                </table>

                <div style={{ marginTop: 20 }}>
                    <Button disabled={!props.readTerms} variant="contained" color="primary" onClick={props.eventHandle}>
                        Start Task
                    </Button>
                </div>
                <div style={{ fontStyle: "italic", marginTop: 10 }}></div>
            </div>}
        </Container>
    );
}

export default connect(
    (state) => state.tasks,
    (dispatch) => bindActionCreators(tasksActions, dispatch)
)(PendingStart);

