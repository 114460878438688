import React from "react";
import { Route, Switch } from "react-router-dom";
import ResponsiveDrawer from "./navigation/Drawer";
import NotFound from "./NotFound";
import LandingPage from "./LandingPage";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import Task from "./components/Task";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0b6d9f"
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#0bbbe9"
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/:linkId" component={Task} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </ThemeProvider>
  );
}

function Layout(props) {
  console.log(props);
  return (
    <div>
      <ResponsiveDrawer rootOutlet={props.children} />
    </div>
  );
}

export default App;
