import { TramOutlined } from "@material-ui/icons";
import * as types from "../actions/actionTypes";

const initialState = {
  task: {},
  isLoading: false,
  error: "",
  successMessage: "",
  isValid: false,
  readTerms: false
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.getTask:
      return {
        ...state,
        isLoading: true,
        error: "",
        successMessage: ""
      };
    case types.getTaskSuccess:
      return {
        ...state,
        task: action.task,
        isLoading: false,
        successMessage: ""
      };
    case types.startTask:
      return {
        ...state,
        isLoading: true,
        error: "",
        successMessage: ""
      };
    case types.startTaskSuccess:
      return {
        ...state,
        task: action.task,
        isLoading: false,
        successMessage: 'Task started! Good luck!'
      };
    case types.submitTask:
      return {
        ...state,
        isLoading: true,
        error: "",
        successMessage: ""
      };
    case types.submitTaskSuccess:
      return {
        ...state,
        task: action.task,
        isLoading: false,
        successMessage: ""
      };
    case types.taskApiRequestError:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
      case types.addCollaborator:
        return {
            ...state,
            isLoading: true,
            error: "",
            successMessage: ""
        };
    case types.addCollaboratorSuccess:
        return {
            ...state,
            isLoading: false,
            successMessage: 'User added',
            username: action.username
        };
      case types.validateCollaborator:
        return {
            ...state,
            isLoading: TramOutlined,
            error: "",
            successMessage: ""
        };
    case types.validateCollaboratorSuccess:
        return {
            ...state,
            isValid: action.isValid,
            isLoading: false,
            successMessage: 'Checks complete. Please click the Start button to proceed'
        };
    case types.readTerms:
        return {
            ...state,
            isLoading: false,
            readTerms: action.readTerms
          };        
    default:
      return state; 
  }
};
