import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ScrollToTop from "./scrollToTop";
import Paper from "@material-ui/core/Paper";

import { useTheme } from "@material-ui/core/styles";
import * as portalStyle from "./portalStyle";

import logo from "../shared/images/CleverbitLogo.png";

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = portalStyle.useStyles();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getYear = () => {
    const d = new Date();
    return d.getFullYear();
  }

  const drawerMenu = (
    <div>
      <Toolbar className={classes.logoBar}>
        <div className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt="logo" />
        </div>
      </Toolbar>

      <div className={classes.drawerStamp}>&copy; Cleverbit Software {getYear()}</div>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6" noWrap className={classes.title}>
            <div className={classes.appLogoContainer}>
              <img src={logo} className={classes.logo} alt="logo" />
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawerMenu}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawerMenu}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Toolbar id="back-to-top-anchor" variant="dense" />
        <Container className={classes.outerContainer}>
          <Container maxWidth="lg" className={classes.innerContainer}>
            <Paper className={classes.containerPaper} elevation={2}>
              <Container maxWidth="md" className={classes.contentContainer}>
                {props.rootOutlet}
              </Container>
            </Paper>
          </Container>
        </Container>
        <ScrollToTop {...props}>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon style={{ color: "white" }} />
          </Fab>
        </ScrollToTop>
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
