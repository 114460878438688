export const taskStatus = {
  pending: "pending-candidate-start",
  started: "task-started",
  submitted: "task-submitted"
};

export const contactDetails = {
    githubEmail: "careers@cleverbit.software",
    githubUsername: "careers-cleverbit"
}

export function showEmail(username) {
  // eslint-disable-next-line
  return `${username}@cleverb` + `it.software`;
}
