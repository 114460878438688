import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as taskActions } from "../redux/actions/tasksActions";
import PendingStart from "./PendingStart";
import Started from "./Started";
import LinearProgress from "@material-ui/core/LinearProgress";
import LandingPage from "../LandingPage";
import Submitted from "./Submitted";
import Container from "@material-ui/core/Container";
import * as constants from "../shared/constants";

class Task extends React.Component {
  componentDidMount() {
    this.ensureDataFetched();
  }

  ensureDataFetched() {
    this.props.getTask(this.props.match.params.linkId);
  }

  startTask = () => {
    this.props.startTask(this.props.match.params.linkId);
  };

  submitTask = completedTask => {
    this.props.submitTask(
      this.props.match.params.linkId,
      completedTask.repositoryLink
    );
  };

  renderSpecific() {
    if (this.props.task.status === constants.taskStatus.pending) {
      return (
        <PendingStart task={this.props.task} readTerms={this.props.readTerms} username={this.props.username} eventHandle={this.startTask} />
      );
    } else if (this.props.task.status === constants.taskStatus.started) {
      return <Started task={this.props.task} eventHandle={this.submitTask} />;
    } else if (this.props.task.status === constants.taskStatus.submitted) {
      return <Submitted />;
    } else {
      return <LandingPage />;
    }
  }

  render() {
    return (
      <>
        {this.props.isLoading ? (
          <div className="loader-message">
            <LinearProgress />
            <div style={{ marginTop: 10, fontStyle: "italic" }}>Loading...</div>
          </div>
        ) : (
          <>{this.renderSpecific()}</>
        )}

        {this.props.successMessage ? (
          <Container maxWidth="lg">
            <p style={{ color: "green" }}>
              <strong>Success: </strong>
              <span id="taskSuccessMessage" style={{ marginLeft: 10 }}>
                {this.props.successMessage}
              </span>
            </p>
          </Container>
        ) : (
          <div></div>
        )}

        {this.props.error ? (
          <Container maxWidth="lg">
            <p style={{ color: "red" }}>
              <strong>Error: </strong>
              <span id="taskError" style={{ marginLeft: 10 }}>
                {this.props.error}
              </span>
            </p>
          </Container>
        ) : (
          <div></div>
        )}
      </>
    );
  }
}

export default connect(
  state => state.tasks,
  dispatch => bindActionCreators(taskActions, dispatch)
)(Task);
