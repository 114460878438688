import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import * as Tasks from "./reducers/tasksReducer";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import { routerReducer } from "react-router-redux";

export default function configureStore(initialState) {
  console.log(
    `You are running this application in ${process.env.NODE_ENV} mode.`
  );

  const reducers = {
    tasks: Tasks.reducer
  };

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
  );
}
