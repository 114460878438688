import React from "react";

export function RawTaskContent(props) {
  function createMarkup() {
    return { __html: props.taskContent };
  }

  return <div dangerouslySetInnerHTML={createMarkup()} />;
}

export default RawTaskContent;
