export const getTask = "GET_TASK";
export const getTaskSuccess = "GET_TASK_SUCCESS";

export const startTask = "START_TASK";
export const startTaskSuccess = "START_TASK_SUCCESS";

export const submitTask = "SUBMIT_TASK";
export const submitTaskSuccess = "SUBMIT_TASK_SUCCESS";

export const taskApiRequestError = "TASK_API_REQUEST_ERROR";

export const addCollaborator = "ADD_COLLABORATOR";
export const addCollaboratorSuccess = "ADD_COLLABORATOR_SUCCESS";

export const validateCollaborator = "VALIDATE_COLLABORATOR";
export const validateCollaboratorSuccess = "VALIDATE_COLLABORATOR_SUCCESS";

export const readTerms = "READ_TERMS";