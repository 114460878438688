const dev = {
  candidatesApiUrl: "https://localhost:44387"
};

const prod = {
  candidatesApiUrl: "https://codingtask-api.cleverbit.software"
};

const config = process.env.NODE_ENV === "production" ? prod : dev;

export default {
  // Add common config values here
  ...config
};
