import * as types from "./actionTypes";
import axios from "axios";
import config from "../../config";

const genericError =
  "Something went wrong, please contact Cleverbit Software or try again.";

export const actionCreators = {
  getTask: linkId => async dispatch => {
    dispatch({ type: types.getTask });

    try {
      const response = await axios.get(
        `${config.candidatesApiUrl}/api/tasks/get-task/${linkId}`
      );

      const task = response.data;

      dispatch({ type: types.getTaskSuccess, task });
    } catch (error) {
      dispatch({
        type: types.taskApiRequestError,
        error: error.response ? error.response.data : genericError
      });
    }
  },
  startTask: linkId => async dispatch => {
    dispatch({ type: types.startTask });

    try {
      const response = await axios.post(
        `${config.candidatesApiUrl}/api/tasks/start-task/${linkId}`
      );

      const task = response.data;

      dispatch({ type: types.startTaskSuccess, task });
    } catch (error) {
      dispatch({
        type: types.taskApiRequestError,
        error: error.response ? error.response.data : genericError
      });
    }
  },
  submitTask: (linkId, repositoryLink) => async dispatch => {
    dispatch({ type: types.submitTask });

    try {
      const response = await axios.post(
        `${config.candidatesApiUrl}/api/tasks/submit-task/${linkId}`,
        { repositoryLink: repositoryLink }
      );

      const task = response.data;

      dispatch({ type: types.submitTaskSuccess, task });
    } catch (error) {
      dispatch({
        type: types.taskApiRequestError,
        error: error.response ? error.response.data : genericError
      });
    }
  },
  addCollaborator: (username, linkId) => async (dispatch) => {
    dispatch({ type: types.addCollaborator, username: username });
    try {
      await axios.post(`${config.candidatesApiUrl}/api/tasks/add-collaborator${linkId}/${username}`, username, linkId);
      console.log(username);

      dispatch({ type: types.addCollaboratorSuccess, username: username });
    } catch (error) {
      dispatch({
        type: types.taskApiRequestError,
        error: error.response ? error.response.data : genericError,
        username: username
      });
    }
  },
  validateCollaborator: (username, linkId) => async dispatch => {
    dispatch({ type: types.validateCollaborator });
    try {
      const response = await axios.get(
        `${config.candidatesApiUrl}/api/tasks/collaborator-validation${linkId}/${username}`, username, linkId
      );

      const isValid = response.data;

      dispatch({ type: types.validateCollaboratorSuccess, isValid });
    } catch (error) {
      
      dispatch({
        type: types.taskApiRequestError,
        error: error.response ? error.response.data : genericError,
      });
    }
  },
  setReadTerms: (checked) => async (dispatch) => {
    dispatch({ type: types.readTerms, readTerms: checked });
  }
 
};
