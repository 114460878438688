import React from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as fileDownloader from "../shared/downloadFile";

export function TaskContent(props) {
  return (
    <>
      {props.functional && props.functional.length ? (
        <>
          <h4>Functional Requirements</h4>
          <div>The application will work as follows:</div>
          <ul style={{ marginTop: 10 }}>
            {props.functional.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        </>
      ) : (
        <div></div>
      )}

      {props.technical && props.technical.length ? (
        <>
          <h4>Technical Requirements</h4>
          <div>The application will work as follows:</div>
          <ul style={{ marginTop: 10 }}>
            {props.technical.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        </>
      ) : (
        <div></div>
      )}

      {props.attachments && props.attachments.length ? (
        <>
          <h4>Task Attachments</h4>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              border: "1px solid lightgrey"
            }}
          >
            {props.attachments.map((item, index) => (
              <div key={index} style={{ maxWidth: 900, margin: 20 }}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  style={{ marginBottom: 5 }}
                  color="primary"
                >
                  {item.name}
                </Typography>

                {fileDownloader.getFileFormat(item.name) === "jpg" ||
                fileDownloader.getFileFormat(item.name) === "png" ? (
                  <>
                    <img
                      style={{ maxWidth: "100%" }}
                      alt={item.name}
                      src={`data:image/${fileDownloader.getFileFormat(item.name)};base64,${
                        item.data
                      }`}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      type="button"
                      color="primary"
                      onClick={() => fileDownloader.downloadFile(item.data, item.name)}
                    >
                      Download
                    </Button>
                  </>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default TaskContent;
