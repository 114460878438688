import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  scrollToTop: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  toolBar: {
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      background: `linear-gradient(90deg, rgb(255, 255, 255) 0%, ${[
        theme.palette.primary.main
      ]} 100%)`
    },
    borderBottom: `10px solid ${[theme.palette.primary.main]}`
  },
  menuButton: {
    color: [theme.palette.primary.main],
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1
  },
  toolbarSpacer: {
    flex: 1
  },
  userMenuIcon: {
    marginRight: 12
  },
  outerContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  innerContainer: {
    padding: "40px 0",
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  containerPaper: {
    minHeight: 600
  },
  contentContainer: {
    paddingTop: 20,
    paddingBottom: 100
  },
  logoBar: {
    textAlign: "center",
    backgroundColor: "white",
    borderBottom: `10px solid ${[theme.palette.primary.main]}`
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: [theme.palette.primary.dark]
  },
  entityMenuList: {
    paddingTop: 25
  },
  entityMenuCard: {
    backgroundColor: [theme.palette.primary.light]
  },
  entityMenu: {
    color: [theme.palette.primary.contrastText]
  },
  drawerIcon: {
    color: [theme.palette.primary.dark],
    minWidth: 0,
    marginRight: 10
  },
  toolbarIcon: {
    color: "white",
    [theme.breakpoints.down("xs")]: {
      color: [theme.palette.primary.main]
    }
  },
  logoContainer: {
    height: "100%",
    width: "100%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  appLogoContainer: {
    height: "100%",
    width: "100%",
    display: "inline-block",
    verticalAlign: "middle",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  drawerListHeader: {
    fontWeight: "bold",
    color: "white",
    textTransform: "uppercase"
  },
  logo: {
    maxHeight: 45,
    marginRight: "auto",
    marginLeft: "auto",
    display: "block"
  },
  drawerStamp: {
    position: "absolute",
    bottom: 25,
    marginLeft: 15,
    color: [theme.palette.primary.contrastText],
    fontSize: 12
  },
  listItemSelected: {
    borderLeft: "5px solid white",
    backgroundColor: "rgba(0, 0, 0, 0.2)"
  }
}));
