export function downloadFile(data, fileName) {
  var binaryString = window.atob(data);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  var blob = new Blob([bytes], { type: "application/octet-stream" });
  var downloadURL = window.URL.createObjectURL(blob);
  var link = document.createElement("a");
  link.href = downloadURL;
  link.download = fileName;
  link.click();
}

export function getFileFormat(filename){
  return (
    filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
    filename
  );
};