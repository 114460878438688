import React from "react";
import Container from "@material-ui/core/Container";

export function Submitted(props) {
  return (
    <Container maxWidth="lg">
      <h4>
        Thank you for submitting your task. Our team will review and get back to
        you shortly.
      </h4>
    </Container>
  );
}

export default Submitted;
